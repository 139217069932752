/** @jsxImportSource @emotion/react */
import Spinner from "Components/shared/Spinner";
import T from "Components/shared/Translate";
import { useUser } from "Contexts/UserContext";
import styles from "./styles";

export default function ReferralBar() {
    const { enrollerFullName } = useUser();

    return (
        <div suppressHydrationWarning>
            {typeof window !== "undefined" ? (
                <div css={styles}>
                    <div className="ref-bar-main" suppressHydrationWarning>
                        <span className="ref-success">
                            {enrollerFullName ? (
                                <>
                                    <span data-testid="referral_bar_text">
                                        <T>create_account_referred_by</T>
                                    </span>{" "}
                                    <strong data-testid="referral_bar_enroller_name">
                                        {enrollerFullName}
                                    </strong>{" "}
                                </>
                            ) : (
                                <Spinner height="24px" />
                            )}
                        </span>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
